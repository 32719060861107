<template>
  <page-structure title="blog" :loading="loading">
    <div class="blog" v-if="articles.length > 0">
      <BlogGrid :articles="articles"></BlogGrid>
    </div>
  </page-structure>
</template>

<script>
// import articles from "../assets/data/blog";
import BlogGrid from "@/components/blog/BlogGrid";

export default {
  name: "blogs",
  data: () => {
    return {
      loading: false,
      articles: [],
    };
  },
  components: { BlogGrid },
  async mounted() {
    this.$emit("setsocial", true);
    try {
      const articles = await this.$store.dispatch("fetchArticles", false);
      this.articles = articles;
      this.loading = false;
    } catch (err) {
      console.error(err);
    }
  },
  metaInfo: {
    title: "Blog",
    titleTemplate: "%s - moldoVAN",
    htmlAttrs: {
      lang: "en",
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/blogPage";
</style>
